'use client';

import { FC, useEffect, useRef } from 'react';
import { useInView } from 'framer-motion';
import { IParagraphProps } from './interfaces';
import styles from './paragraph.module.scss';
import { Typography } from '@/shared/ui/typography';

export const Paragraph: FC<IParagraphProps> = ({ children }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const isInView = useInView(containerRef);

  useEffect(() => {
    if (isInView) {
      containerRef.current?.classList.add(styles['container-in-view']);
    }
  }, [isInView]);

  return (
        <div ref={containerRef} className={styles.container}>
            <div className={styles.line} />
            <Typography as="p" variant="p">{children}</Typography>
            <div className={styles.line} />
        </div>
  );
};