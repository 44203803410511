'use client';

import React, { FC, Children, useRef, useEffect } from 'react';
import { useInView, motion } from 'framer-motion';
import styles from './card-list.module.scss';
import { ICardListProps } from './interfaces';

const variants = {
  hidden: {
    scaleY: 0,
  },
  visible: {
    scaleY: 1,
  },
};

export const CardList: FC<ICardListProps> = ({ children }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const isInView = useInView(containerRef);

  useEffect(() => {
    if (isInView) {
      containerRef.current?.classList.add(styles['container-in-view']);
    }
  }, [isInView]);

  return (
        <div ref={containerRef} className={styles.container}>
            {Children.map(children, (child, index) => (
                <>
                    {index != 0 && <motion.span variants={variants} className={styles.separator}/>}
                    {child}
                </>
            ))}
        </div>
  );
};