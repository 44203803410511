'use client';

import { ComponentType, FC, useRef } from 'react';
import { useInView } from 'framer-motion';
import dynamic from 'next/dynamic';
import Skeleton from 'react-loading-skeleton';
import styles from './carousel.module.scss';
import { Carousel as CarouselTypeProps, CarouselTypes } from './interfaces';

const Default = dynamic(() => import('./components/default').then((module) => ({ default: module.Default })), { ssr: false, loading: () => <div className={styles.loading}><div className={styles.skeleton}><Skeleton height="100%" /></div></div> });
const Parallax = dynamic(() => import('./components/parallax').then((module) => ({ default: module.Parallax })));

const Component: { [key in CarouselTypes]: ComponentType<any> } = {
  fullwidth: Default,
  parallax: Parallax,
};

export const Carousel: FC<CarouselTypeProps> = ({ type = 'fullwidth' as CarouselTypes, ...restProps }) => {
  const CarouselType = Component[type];
  const ref = useRef<HTMLDivElement>(null);
  const isInView = useInView(ref, { once: true });

  return (
        <div ref={ref} className={styles.container}>
            {isInView && <CarouselType {...restProps} />}
        </div>
  );
};