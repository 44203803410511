import { LazyLoadImage, LazyLoadImageProps } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export const Image = (props: LazyLoadImageProps) => (
    <LazyLoadImage
        width="100%"
        height="100%"
        effect="blur"
        {...props}
    />
);