'use client';

import { useEvent, useUnit } from 'effector-react';
import { $pageStore, updateValue } from '@/features/page/index';
import { IPageData } from '@/features/page/interfaces';

export const usePageStore = <T>() => useUnit<T>($pageStore);
export const usePageDispatch = <T>() => useEvent<Partial<T>>(updateValue);

export const usePageDataStore = () => usePageStore<IPageData>();
