enum Methods {
  'get' = 'get',
  'post' = 'post',
}

type EnumKeys = keyof typeof Methods;

type IClassMethods = {
  [key in EnumKeys]?: <T>(url?: string, data?: any) => Promise<T | void> | void
};

class ApiClient implements IClassMethods {
  protected baseUrl = '';

  protected options: RequestInit = {
    cache: 'no-store',
  };

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  protected request = async (url?: string) => {
    const response = await fetch(`${this.baseUrl}${url}`, this.options);

    return response.json();
  };

  get = async <T>(url?: string) => {
    this.options.method = 'GET';
    delete this.options.body;

    return await this.request(url) as T;
  };

  post = async <T>(url?: string, data?: any) => {
    this.options.method = 'POST';
    this.options.body = JSON.stringify(data);

    return await this.request(url) as T;
  };
}

export const apiClient = new ApiClient(`${process.env.BASE_URL}/api`);
export const fetchClient = new ApiClient(process.env.NEXT_PUBLIC_API_URL!);
