import { IContacts } from '@/shared/interfaces/contacts';
import { IFooter } from '@/shared/interfaces/footer';
import { IHeader } from '@/shared/interfaces/header';
import { IMetadata } from '@/shared/interfaces/metadata';

export interface IPage {
  title: string,
  more: string,
  slider: string[],
  advantages?: string[]
  description?: string
  iconDescription?: number,
  icon?: string
}

export interface IPageStore<T> {
  header: Partial<IHeader>
  footer: Partial<IFooter>
  page: Partial<T>
  contacts: Partial<IContacts>
}

export interface IServerPage<T> {
  header: Partial<IHeader>
  footer: Partial<IFooter>
  page: Partial<T> | { error: string }
  contacts: Partial<IContacts>
}

export enum PageType {
  home = '/home.v1.getHome',
  embankment = '/detail.v1.getPrivateEmbankmentDetail',
  parks = '/detail.v1.getWaterParksDetail',
  apartments = '/detail.v1.getLuxuryApartmentsDetail',
  infrastructure = '/detail.v1.getLandscapingInfrastructureDetail',
  architecture = '/detail.v1.getArchitectureDetailsDetail',
  lobby = '/detail.v1.getStatusLobbieDetail',
  news = '/news.v1.getNewsList',
  stocks = '/stocks.v1.getStocksList',
  newsSingle = '/news.v1.getNews',
  stocksSingle = '/stocks.v1.getStocks',
  booklets = '/booklets.v1.getBookletsList',
  progress = '/constructionProgress.v1.getConstructionProgress',
  catalog = '/catalog.v1.getCatalog',
  catalogList = '/catalog.v1.getCatalogList',
  flat = '/flat.v1.getFlat',
  visual = '/visual.v1.getVisual',
  sendForm = '/form.v1.sendForm',
  visualHouseList = '/visual.v1.getVisualHouseList',
  visualFloor = '/visual.v1.getVisualFloor',
  visualFloorList = '/visual.v1.getVisualFloorList',
  visualFlatList = '/visual.v1.getVisualFlatList',
  visualHome = '/visual.v1.getVisualHome',
  notFound = '/contacts.v1.getContacts',
}

export interface IPageBaseData {
  title: string
  meta: IMetadata
  breadCrumbs?: {
    title: string
    link: string
  }[]
}

export interface IPageData {
  advantages?: string[]
  description?: string
  meta?: IMetadata
  slider?: string[]
  title?: string
  iconDescription?: string
  icon?: string
  breadCrumbs?: {
    title: string
    link: string
  }
}