import { createStore, createEffect, createEvent } from 'effector';
import { PageType } from '@/features/page/interfaces';
import { IRequest } from '@/shared/interfaces/request';
import { fetchClient } from '@/utils/apiClient';

export const fetchPageFx = createEffect(async ({ pageType, searchParams }: { pageType: keyof typeof PageType, searchParams?: { [key: string]: any } }) => {
  try {
    const pageData = await fetchClient.post<IRequest<any>>(PageType[pageType], { ...searchParams });

    return pageData.result.response;
  } catch (e: any) {
    console.log(e);
    return { error: e.message };
  }
});

export const updateValue = createEvent<any>();

export const $pageStore = createStore<any>({});

$pageStore.on(updateValue, (data, value) => {
  console.log(value);
  return  ({ ...data, ...value });
});
